const ExcelJS = require('exceljs');
const File = require('./File');
// const dateDiff = require("@/Utils/DateDiff");

exports.cameras = async (dataS = [], title = "", filename = "") => {
  let columns = [
    { header: "ID", key: "id", },
    { header: "Nome", key: "name", width: 32 },
    { header: "Cliente Id", key: "clientId", width: 16 },
    { header: "Cliente", key: "clientName", width: 32 },
    { header: "Grupo Id", key: "groupId", width: 16 },
    { header: "Grupo", key: "groupName", width: 32 },
    { header: "Url", key: "url", width: 32 },
    { header: "Usuário", key: "user", },
    { header: "Senha", key: "password", },
    { header: "Zona", key: "zone", },
    { header: "Enviando", key: "enviando", width: 16 },
    { header: "Cadastro", key: "FcreatedAt", width: 24 },
    { header: "Editado", key: "FupdatedAt", width: 24 },
    { header: "Desativado", key: "FdeletedAt", width: 24 },
    { header: "Ultimo Evento", key: "FlastEvent", width: 24 },
    { header: "Conectada", key: "conected", width: 24 },
    { header: "Usando Proxy", key: "proxyId", width: 24 },
  ];

  let rows = [...dataS];

  rows.forEach(e => {
    e.FcreatedAt = new Date(e.createdAt).toLocaleString();
    e.FupdatedAt = new Date(e.updatedAt).toLocaleString();
    e.FdeletedAt = e.deletedAt ? new Date(e.deletedAt).toLocaleString() : "Ativa";
    e.FlastEvent = e.sts.lastEvent ? new Date(e.sts.lastEvent).toLocaleString() : "";
    e.conected = e.sts.conected ? "SIM" : "NÃO";
    e.enviando = e.sendEvent ? "SIM" : "NÃO";
    e.proxyId = e.proxyId ? "SIM " + e.proxyId : "NÃO";
  });

  createExcel(rows, columns, title, filename);
}

exports.camerasSpace = async (dataS = [], title = "", filename = "", rawRows = []) => {
  let columns = [
    { header: "ID", key: "cameraId", },
    { header: "Câmera", key: "cameraName", width: 32 },
    { header: "Cliente", key: "clientName", width: 32 },
    { header: "Grupo", key: "groupName", width: 32 },
    { header: 'Tamanho', key: 'size', width: 32 },
    { header: 'ID do Cliente', key: 'clientId', width: 32 },
    { header: 'Tamanho em bites', key: 'sizeBytes', width: 32 },
  ];

  let rows = [...dataS];

  createExcel(rows, columns, title, filename, rawRows);
}

exports.camerasSts = async (dataS = [], title = "", filename = "") => {
  let columns = [
    { header: "Mês", key: "mounth", },
    { header: "Contagem", key: "length" },
  ];

  let rows = [...dataS];

  let mounths = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set",
    "Out", "Nov", "Dez"];

  let data = [];

  rows.forEach(e => {
    let mes = mounths[new Date(e.createdAt).getMonth()];
    let ano = new Date(e.createdAt).getFullYear();
    let idx = data.findIndex(e => e.mounth == `${mes}/${ano}`);
    if (idx >= 0) {
      data[idx].length++;
      return;
    } else {
      data.push({ mounth: `${mes}/${ano}`, length: 1 });
    }
  });

  createExcel(data, columns, title, filename);
}


async function createExcel(rows, columns, title, filename) {
  let data = [{ columns, rows, title }]


  const workbook = new ExcelJS.Workbook();
  workbook.creator = 'STV Segurança';
  workbook.created = new Date();

  try {
    if (!Array.isArray(data)) {
      throw 'data not correct'
    }
    let contaisData = false
    data.forEach(e => {
      if (!e.rows.length) {
        return
      }
      contaisData = true
      const worksheet = workbook.addWorksheet(e.nameSheet);

      worksheet.columns = e.columns

      if (Array.isArray(e.rows)) {
        e.rows.forEach(row => {
          let rowD = {}
          let maxLines = 0;
          for (let valor in row) {
            rowD[valor] = row[valor];
            // obtém o número máximo de linhas de texto das rows p/ setar a altura da row
            if (typeof row[valor] === 'string') {
              let lineBreaks = row[valor].split(/\r\n|\r|\n/).length;
              if (lineBreaks > maxLines)
                maxLines = lineBreaks;
            }
          }
          worksheet.addRow(rowD).height = maxLines > 1 ? maxLines * 15 : undefined;
        });
      }
      worksheet.getRow(1).font = { size: 12, bold: true };
      worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
    })


    if (contaisData) {
      let buffer = await workbook.xlsx.writeBuffer();
      let blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
      File.downloadFile(blob, filename);
    } else {
      return null
    }

  } catch (error) {
    console.log(error);
    return null
  }
}

// function getIntervalo(inicio, fim) {
//   let dif = dateDiff.differenceDate(inicio, fim);
//   let res;
//   if (dif.day > 0)
//     res = `${dif.day}d, ${dif.hour}h, ${dif.minute}m`;
//   else
//     res = `${dif.hour < 10 ? "0" + dif.hour : dif.hour}:${dif.minute < 10 ? "0" + dif.minute : dif.minute}:${dif.second < 10 ? "0" + dif.second : dif.second}`;
//   return res;
// }